export const ChainId = {
  EDIFICE: 13579,
  EDIFICE_TESTNET: 13580,
  LOCAL: 31337,
}

export const DEFAULT_CHAIN_ID = process.env.REACT_APP_WEB3_LOCAL === 'true' ? ChainId.LOCAL : ChainId.EDIFICE_TESTNET

export const DNR_CURRENCY = {
  name: 'Dinar',
  symbol: 'DNR',
  decimals: 18,
}

export const LOCAL_CURRENCY = {
  name: 'Local',
  symbol: 'LOCAL',
  decimals: 18,
}

function isExtendedChainInformation(chainInformation) {
  return !!chainInformation.nativeCurrency
}

export function getAddChainParameters(chainId) {
  const chainInformation = CHAINS[chainId]
  if (isExtendedChainInformation(chainInformation)) {
    return {
      chainId,
      chainName: chainInformation.name,
      nativeCurrency: chainInformation.nativeCurrency,
      rpcUrls: chainInformation.urls,
      blockExplorerUrls: chainInformation.blockExplorerUrls,
    }
  }

  return chainId
}

export const CHAINS = {
  [ChainId.EDIFICE]: {
    id: ChainId.EDIFICE,
    name: 'Edifice',
    icon: null,
    nativeCurrency: DNR_CURRENCY,
    urls: ['https://rpc.edifice.network'],
    blockExplorerUrls: ['https://explorer.edifice.network'],
    available: false,
    testnet: false,
  },
  [ChainId.EDIFICE_TESTNET]: {
    id: ChainId.EDIFICE_TESTNET,
    name: 'Edifice Testnet',
    icon: null,
    nativeCurrency: DNR_CURRENCY,
    urls: ['https://rpc-testnet.edifice.network'],
    blockExplorerUrls: ['https://explorer-testnet.edifice.network'],
    available: true,
    testnet: true,
  },
  [ChainId.LOCAL]: {
    id: ChainId.LOCAL,
    name: 'Localhost',
    icon: null,
    nativeCurrency: LOCAL_CURRENCY,
    urls: ['http://127.0.0.1:8545'],
    testnet: true,
    available: process.env.REACT_APP_WEB3_LOCAL === 'true',
  },
}

export const ALL_SUPPORTED_CHAIN_IDS = Object.values(ChainId).filter(
  id => typeof id === 'number',
)

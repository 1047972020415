import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useWeb3React } from '@web3-react/core'

import { networkConnection } from 'web3/connection'
import { WalletConnectPopup } from 'web3/connection/WalletConnectV2'
import { getAddChainParameters, CHAINS } from 'web3/chains'
import { isSupportedChain } from 'web3/utils'

import { setSiteNetworkId } from './reducer'

export function useSiteNetworkId() {
  return useSelector(state => state.network.siteNetworkId)
}

export function useActiveChainId() {
  const { chainId, isActive } = useWeb3React()
  const siteNetworkId = useSiteNetworkId()

  if (!isActive) {
    return siteNetworkId
  }

  return chainId
}

export function useFallbackChainId() {
  const { chainId, isActive } = useWeb3React()
  const siteNetworkId = useSelector(state => state.network.siteNetworkId)

  if (!isActive || !isSupportedChain(chainId)) {
    return siteNetworkId
  }

  return chainId
}

export function useActiveNetwork() {
  const chainId = useActiveChainId()

  return (chainId && isSupportedChain(chainId)) ? !!CHAINS[chainId] : undefined
}

export function useSwitchNetwork() {
  const dispatch = useDispatch()
  const { connector, account } = useWeb3React()

  return useCallback(async chainId => {
    if (!account) {
      await networkConnection.connector.activate(chainId)
    } else {
      await connector.activate(connector instanceof WalletConnectPopup ? chainId : getAddChainParameters(chainId))
    }

    if (isSupportedChain(chainId)) {
      dispatch(setSiteNetworkId(chainId))
    }
  }, [dispatch, account, connector])
}
